import { createApp } from 'vue';
import App from './App.vue';
import './index.css';
import router from './router';
import store from './store';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

import { getText } from '@/mixins/lang';
import constants from '@/constants';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ENV_PRODUCTION, getConfigValue, getSlabEnv } from '@/config/config';
import {
  isProbablyMobile,
  isProbablyiOS,
  isProbablySafari,
} from '@/helpers/device';

axios.defaults.baseURL = getConfigValue('API_BASE_URL');
// this tells Cloudflare we are making a request from JavasScript, so it doesn't return 200 when presenting the challenge page:
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const app = createApp(App)
  .use(store)
  .use(router)
  .mixin({
    methods: {
      text(key: string) {
        return getText(constants.defaultLocale, key);
      },

      isProbablySafari,

      isProbablyiOS,

      isProbablyMobile,

      confirmLeave() {
        // this is for a 'soft exit' - i.e. the user clicks a button that we have control over, so we can ask them if they want to leave.
        // for 'hard navigation', see preventNavigation
        if (store.state.warnBeforeLeaveMessage) {
          const result = confirm(store.state.warnBeforeLeaveMessage);
          if (result) {
            store.commit('OK_TO_LEAVE');
          }

          return result;
        }

        return true;
      },

      preventNavigation(event: Event) {
        // This is to prevent a hard navigation - e.g. if the user refreshes or hits the back button.
        if (store.state.warnBeforeLeaveMessage) {
          // Cancel the event
          event.preventDefault();
          // Chrome requires returnValue to be set
          event.returnValue = store.state.warnBeforeLeaveMessage;
        }
      },
    },
    beforeMount() {
      window.addEventListener('beforeunload', this.preventNavigation);
    },

    beforeDestroy() {
      window.removeEventListener('beforeunload', this.preventNavigation);
    },
  });

Sentry.init({
  app,
  dsn:
    getSlabEnv() === ENV_PRODUCTION
      ? 'https://e537d63202d94e8f9353713edf874ad4@o422871.ingest.sentry.io/5352181'
      : 'https://a7584f5e54ee485a8117c5c65d74047e@o422871.ingest.sentry.io/5872890',
  environment: getSlabEnv(),
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
});

app.mount('#app');
