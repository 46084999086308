import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LoadingView from '../views/global/LoadingView.vue';
import UploadRecordingView from '../views/video/UploadRecordingView.vue';
import VideoRecordWebView from '../views/video/VideoRecordWebView.vue';
import Constants from '@/constants';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getConfigValue } from '@/config/config';
import NewUploadRecordingView from '@/views/video/NewUploadRecordingView.vue';
import VideoRecordLandingView from '@/views/video/VideoRecordLandingView.vue';
import UploadCompleteView from '@/views/video/UploadCompleteView.vue';
import SafariInputTestView from '@/views/video/SafariInputTestView.vue';
import UppyView from '@/views/video/UppyView.vue';

declare module 'vue-router' {
  // here we can define all meta options, such as requiresAuth:
  interface RouteMeta {
    hideTopBarOnMobile?: boolean;
    title?: string;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: LoadingView,
    beforeEnter() {
      window.location.href = getConfigValue('API_BASE_URL');
    },
  },
  {
    path: '/video/landing',
    name: 'VideoRecordLandingView',
    component: VideoRecordLandingView,
    meta: {
      hideTopBarOnMobile: true,
    },
  },
  {
    path: '/safari',
    name: 'SafariInputTestView',
    component: SafariInputTestView,
    meta: {
      hideTopBarOnMobile: false,
    },
  },

  {
    path: '/uppy',
    name: 'UppyView',
    component: UppyView,
    meta: {
      hideTopBarOnMobile: false,
    },
  },

  {
    path: '/video/record-web',
    name: 'VideoRecordWebView',
    component: VideoRecordWebView,
    meta: {
      hideTopBarOnMobile: true,
    },
  },
  {
    path: '/video/record-complete',
    name: 'UploadCompleteView',
    component: UploadCompleteView,
    meta: {
      hideTopBarOnMobile: true,
    },
  },
  {
    path: '/video/upload-recording/:blobUrl',
    name: 'UploadRecordingView',
    component: UploadRecordingView,
  },
  {
    path: '/video/new-upload-recording/:blobUrl',
    name: 'NewUploadRecordingView',
    component: NewUploadRecordingView,
  },
  {
    path: '/health',
    name: 'health',
    // route level code-splitting
    // this generates a separate chunk (health.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "health" */ '../views/global/HealthView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} | ${Constants.appName}`;
  }
});

export default router;
