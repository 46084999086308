<template>
  <div class="w-full bg-dark-grey">
    <!-- Header -->
    <div class="py-20 sm:py-20 md:py-16">
      <div class="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h1
          class="text-center text-4xl tracking-tight text-white md:text-5xl lg:text-6xl"
        >
          <span>Steplab </span><span class="font-bold text-yellow">Record</span>
        </h1>

        <slot name="cta"></slot>
      </div>
    </div>

    <!-- Overlapping cards -->
    <section
      class="relative z-10 mx-auto max-w-7xl rounded-t-xl bg-light-grey px-4 pt-10 pb-32 sm:px-6 lg:px-8"
      aria-labelledby="contact-heading"
    >
      <h2
        class="mb-20 text-center text-3xl font-bold tracking-tight text-slate sm:text-left md:text-3xl lg:text-4xl"
      >
        Tips for success
      </h2>

      <div
        class="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8"
      >
        <div
          v-for="card in helperCards"
          :key="card.name"
          class="flex flex-col rounded-2xl bg-white shadow-xl"
        >
          <div class="relative flex-1 px-6 pt-16 pb-8 md:px-8">
            <div
              class="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-yellow p-5 shadow-lg"
            >
              <component
                :is="card.icon"
                class="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </div>
            <h3 class="text-gray-900 text-xl font-medium">{{ card.name }}</h3>
            <p
              v-html="card.description"
              class="text-gray-500 mt-4 text-base"
            ></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  SparklesIcon,
  MicrophoneIcon,
  AtSymbolIcon,
} from '@heroicons/vue/20/solid';
import BaseContentCard from '@/components/BaseContentCard.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GetReadyToRecordContent',
  components: {
    BaseContentCard,
    MicrophoneIcon,
    SparklesIcon,
  },
  props: {
    native: Boolean,
  },
  data() {
    return {
      helperCards: [
        {
          name: 'Move close to get good audio',
          href: '#',
          description:
            'For even better sound, try a <a class="underline decoration-slate" href="https://www.amazon.co.uk/s?k=lavalier+microphone+wireless&crid=3JEJMG5JFE277&sprefix=lavalier+microphone+wireles%2Caps%2C74&ref=nb_sb_noss_2" target="_blank" rel="nofollow"> clip-on wireless microphone</a>',
          icon: MicrophoneIcon,
        },
        {
          name: 'Keep it short and focussed',
          href: '#',
          description:
            "You can upload multiple videos if you aren't happy with the first one",
          icon: SparklesIcon,
        },
        {
          name: 'Give us feedback',
          href: '#',
          description:
            'If you have issues or feedback for us, email at <a class="underline decoration-slate" href="mailto:hello@steplab.co">hello@steplab.co</a>',
          icon: AtSymbolIcon,
        },
      ],
    };
  },
});
</script>
