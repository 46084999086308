<template>
  <div v-if="isLoading" class="m-auto text-center">
    <base-loading-indicator />
  </div>

  <div class="px-10" v-if="!isLoading && !tokenValid">
    <base-content-card>
      <p>{{ tokenInvalidUserFacingmessage }}</p>
    </base-content-card>
  </div>

  <div v-if="!isLoading && tokenValid">
    <base-content-card v-if="showiOSWarning">
      <div class="flex flex-col justify-between">
        <div class="mb-1">
          <h3 class="text-2xl">Steplab Record App</h3>
          <p>
            The Steplab Record App is now available. We strongly recommend it
            for uploading for recording and uploading videos
          </p>
        </div>

        <a class="m-auto mb-2 max-w-full" href="https://steplab.co/record-app">
          <base-button class="m-auto mt-3 bg-blue">
            <template v-slot:text>Download app</template>
          </base-button>
        </a>

        <p class="m-auto">or</p>

        <base-button
          class="m-auto mt-3 bg-blue"
          @click="showiOSWarning = false"
        >
          <template v-slot:text>Proceed on web</template>
        </base-button>
      </div>
    </base-content-card>

    <GetReadyToRecordContent v-if="!showiOSWarning">
      <template v-slot:cta>
        <div
          class="mt-20 flex flex-col items-center gap-y-0.5 text-white sm:gap-y-2.5"
        >
          <router-link
            class="m-auto max-w-full"
            :to="{
              name: 'VideoRecordWebView',
              query: {
                token: this.$route.query.token,
                context: this.$route.query.context,
                nb: this.$route.query.nb,
              },
            }"
          >
            <base-button-with-icon class="m-auto mb-2 w-52 bg-blue sm:m-0">
              <template v-slot:icon>
                <VideoCameraIcon />
              </template>
              <template v-slot:text>
                <span class="text-xl"> Record new </span></template
              >
            </base-button-with-icon>
          </router-link>

          <div class="text-center">or</div>

          <base-button-with-icon
            class="m-auto mt-2 w-52 bg-blue sm:m-0"
            @click="clickInput()"
          >
            <template v-slot:icon>
              <FolderIcon />
            </template>
            <template v-slot:text>
              <span class="text-xl"> Choose file </span></template
            >
          </base-button-with-icon>
        </div>

        <!-- The hidden file `input` for opening the native camera -->
        <input
          ref="videoSelectInput"
          id="videoSelectInput"
          type="file"
          class="hidden"
          accept="video/*"
          @change="onInputChange"
        />
      </template>
    </GetReadyToRecordContent>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import BaseLoadingIndicator from '@/components/BaseLoadingIndicator.vue';
import BaseButtonWithIcon from '@/components/BaseButtonWithIcon.vue';
import GetReadyToRecordContent from '@/components/GetReadyToRecordContent.vue';
import { VideoCameraIcon, FolderIcon } from '@heroicons/vue/24/solid';
import router from '@/router';
import { isProbablyiOS, isProbablySafari } from '@/helpers/device';
import axios from 'axios';
import { ENV_PRODUCTION, getConfigValue, getSlabEnv } from '@/config/config';
import BaseContentCard from '@/components/BaseContentCard.vue';
import BaseButton from '@/components/BaseButton.vue';

export default defineComponent({
  components: {
    BaseButton,
    BaseContentCard,
    BaseButtonWithIcon,
    GetReadyToRecordContent,
    BaseLoadingIndicator,
    VideoCameraIcon,
    FolderIcon,
  },

  mounted() {
    this.validateToken(this.$route.query.token.toString());
  },

  data() {
    return {
      tokenInvalidUserFacingmessage: this.text('videoUploadTokenInvalid'),
      isLoading: true,
      tokenValid: false,
      selectedMediaLocalUrl: '',
      showiOSWarning: isProbablyiOS(),
    };
  },

  methods: {
    validateToken(token) {
      axios
        .get(`/content/video/record/validate-token/${token}`)
        .then(() => {
          this.tokenValid = true;
        })
        .catch((error) => {
          this.tokenValid = false;
          if (
            error.toString().includes('Network Error') &&
            getSlabEnv() !== ENV_PRODUCTION
          ) {
            alert(
              `Unable to connect to the server ${getConfigValue(
                'API_BASE_URL'
              )}. If you are on a different network to your ususal one, please speak to Steplab tech support to ensure this network is authorised to upload videos.`
            );
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    isProbablyiOS,
    isProbablySafari,
    onInputChange(event) {
      const target = event.target;
      const file = target.files[0];

      this.selectedMediaLocalUrl = URL.createObjectURL(file);

      this.upload();
    },

    clickInput() {
      const videoSelectInput = this.$refs.videoSelectInput;
      videoSelectInput.click();
    },

    upload() {
      router.push({
        path: `/video/new-upload-recording/${encodeURIComponent(
          this.selectedMediaLocalUrl
        )}`,

        query: {
          uploadType: 'native',
          token: this.$route.query.token,
          context: this.$route.query.context,
        },
      });
    },
  },
});
</script>

<style scoped></style>
